var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "pa-0 py-0 mb-3 mt-3" },
        [
          _c("v-card-title", { staticClass: "mb-1 py-0" }, [
            _vm._v(_vm._s(_vm.$t("applications.application-status")))
          ]),
          _c(
            "v-card-text",
            { staticClass: "my-0 py-0" },
            [
              _vm.status
                ? _c("v-alert", {
                    attrs: {
                      prominent: "",
                      color: "accent",
                      dark: "",
                      border: "top",
                      icon: "mdi-account-check"
                    },
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("applications.found-financially-responsible")
                      )
                    }
                  })
                : _c(
                    "div",
                    [
                      _c("v-alert", {
                        attrs: {
                          prominent: "",
                          color: "error",
                          dark: "",
                          border: "top",
                          icon: "mdi-account-alert"
                        },
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "applications.not-found-financially-responsible"
                            )
                          )
                        }
                      }),
                      _c("v-checkbox", {
                        staticClass: "my-1 py-0",
                        attrs: {
                          label: _vm.$t(
                            "applications.change-to-financially-responsible"
                          ),
                          value: "Applicant"
                        },
                        model: {
                          value: _vm.applicantType,
                          callback: function($$v) {
                            _vm.applicantType = $$v
                          },
                          expression: "applicantType"
                        }
                      })
                    ],
                    1
                  )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm.status
                ? _c(
                    "v-btn",
                    {
                      attrs: { large: "", color: "primary" },
                      on: { click: _vm.continueStatus }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-clipboard-account")
                      ]),
                      _vm._v(_vm._s(_vm.$t("applications.continue")) + " ")
                    ],
                    1
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: {
                        large: "",
                        color: "primary",
                        disabled: _vm.applicantType != "Applicant"
                      },
                      on: { click: _vm.continueStatus }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-clipboard-check")
                      ]),
                      _vm._v(
                        _vm._s(_vm.$t("applications.change-application")) + " "
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }