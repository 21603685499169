<template>
    <div>
        <v-card class="pa-0 py-0 mb-3 mt-3">
            <v-card-title class="mb-1 py-0">{{
                $t("applications.application-status")
            }}</v-card-title>
            <v-card-text class="my-0 py-0">
                <v-alert
                    v-if="status"
                    prominent
                    color="accent"
                    dark
                    border="top"
                    icon="mdi-account-check"
                    v-html="$t('applications.found-financially-responsible')"
                >
                </v-alert>

                <div v-else>
                    <v-alert
                        prominent
                        color="error"
                        dark
                        border="top"
                        icon="mdi-account-alert"
                        v-html="
                            $t('applications.not-found-financially-responsible')
                        "
                    >
                    </v-alert>
                    <v-checkbox
                        v-model="applicantType"
                        :label="
                            $t('applications.change-to-financially-responsible')
                        "
                        value="Applicant"
                        class="my-1 py-0"
                    ></v-checkbox>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    large
                    color="primary"
                    @click="continueStatus"
                    v-if="status"
                >
                    <v-icon class="mr-2">mdi-clipboard-account</v-icon
                    >{{ $t("applications.continue") }}
                </v-btn>
                <v-btn
                    v-else
                    large
                    color="primary"
                    @click="continueStatus"
                    :disabled="applicantType != 'Applicant'"
                >
                    <v-icon class="mr-2">mdi-clipboard-check</v-icon
                    >{{ $t("applications.change-application") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
export default {
    name: "applicant-type-status",
    components: {},
    props: {
        status: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            // Assuming we are continuing if status is successful
            applicantType: "Non Applicant",
        };
    },
    methods: {
        continueStatus() {
            this.$emit("update", this.applicantType);
        },
    },
};
</script>

<style scoped>
</style>